import type { Service } from '../domain/service.interface';
import type { Industry } from '$lib/domain/industry.interface';

export const relatedUrlList: string [] = [
  '/how-to-build-a-product-from-scratch/',
  '/healthcare-of-the-future/',
  '/basics-of-developing-on-demand-service-app/',
  '/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
  '/our-works/construction-supply-chain-automation/'
];
export const services: Service[] = [
  {
    iconName: 'startup',
    title: 'Startup studio',
    content: "As your tech business partner, we can leverage our expertise to bring your idea to life, timely and within budget — even if you have just a raw concept and hesitate about what to do next. We’ll crystallize your vision into tech requirements, taking it from the first line of code to product launch and further.",
    url:'/software-development-for-startups/',
  },
  {
    iconName: 'productDesign',
    title: 'Product design & development',
    content: 'As an experienced software development service provider, we can build a technical solution for your customers or the company’s internal needs. Our team will take care of ideation and discovery, coding and QA, release and post-release support.',
    url:'/product-development-services/',
  },
  {
    iconName: 'teamStars',
    title: 'Team extension',
    content: 'Augment your existing team with additional talent, with no hassle of hiring and recruitment. As the top software development company, we can provide you with an entire dedicated team or individual experts: BAs, project managers, designers, and software engineers. You will get anyone needed aboard, and they will feel like part of your company.',
    url:'/team-extension-services/',
  },
  {
    iconName: 'techSupport',
    title: 'Technical support',
    content: 'The product evolution doesn’t stop on a release date. Our software development service company offers a full range of tech support and software maintenance services. We can resolve recurrent problems and handle performance monitoring, regular updates, upgrades, and beyond.',
    url:'/support-and-maintenance/',
  },
];
export const expertises: Service[] = [
  {
    iconName: 'webDevelopment',
    title: 'Web application development',
    content: 'We are well-versed in modern web development technologies. Our web development team can build an attractive website or web app with an intuitive frontend, a stable backend, and a perfectly structured database.',
    url:'/web-development/',
  },
  {
    iconName: 'mobileDevelopment',
    title: 'Mobile application development',
    content: 'Our team has a proven track record of successfully completed mobile app development projects. We know how to build perfect Android, iOS, or cross-platform mobile apps with intuitive design and just the right set of features to win users’ hearts and minds.',
    url:'/mobile-development/',
  },
  {
    iconName: 'uiUxDesign',
    title: 'UI/UX design',
    content: 'Our expertise covers all aspects of visual and user experience design, from user research and prototyping to usability testing. We create appealing, fast-responsive, and easy-to-navigate interfaces that mirror the brand’s identity and promote customer attribution and retention.',
    url:'/ui-ux-design-services/',
  },
  {
    iconName: 'qaTesting',
    title: 'QA services',
    content: 'We are adept in all aspects of quality assurance. We can create test scripts to ensure maximum coverage, set up scalable and versatile testing environments, run all types of tests, and more. Nothing flies under the radar of our QA team.',
    url:'/quality-assurance/',
  },
  {
    iconName: 'crmBusiness',
    title: 'Business application development',
    content: 'with a vast project portfolio spanning domains, we are well aware of business-related challenges — which makes us masters of enterprise software development. We can craft powerful ERP, CRM, and other corporate solutions perfectly aligned with your business goals.',
    url:'/enterprise-application-development-services/',
  },
];
export const industries: Industry[] = [
  {
    title: 'Healthcare',
    content: 'Whether you are a startup ambitious to make this world a safer place with a novel health tech solution or a medical professional looking to streamline processes in your clinic, our team can help you out.<br><br>As a trusted healthcare software development service provider, we can help you create a telemedicine platform, an EHR system, or a LIMS that complies with the standards of modern health tech.',
    url: '/healthcare-software-development/',
    icon: 'healthcare.webp',
  },
  {
    title: 'Logistics',
    content: 'Our software development company can empower freight forwarders, manufacturers, distributors, warehouse owners, and other supply chain participants with high-end custom solutions.<br><br>Logistics software developed by our team can optimize routes based on weather forecasts and traffic, track shipments, anticipate delays, digitize paperwork, and otherwise streamline supply chain processes — thus eliminating inefficiencies and cutting expenses.',
    url: '/logistics-software-development/',
    icon: 'logistics.webp',

  },
  {
    title: 'GreenTech',
    content: 'Partner with Exoft to deliver software solutions that convert your sustainability efforts into meaningful environmental change and long-term business value. We can take your GreenTech project from the first idea to its successful growth.<br><br>As a trusted GreenTech development company with expertise in cloud computing, AI, and IoT, we build solutions for managing waste, reducing reliance on nonrenewables, and achieving net-zero transition.',
    url: '/greentech-software-development/',
    icon: 'greenTech.webp'
  },
  {
    title: 'Finance',
    content: 'We are a trusted software development company with solid knowledge of the finance domain. We can build scalable solutions that securely deal with large amounts of sensitive data in line with all regulations.<br><br>We can help you with solutions for mobile banking, personal budgeting, invoice management, and beyond. Fintech products built by our team help users transfer funds securely, set budgeting goals, get financial advice, and much more.',
    url: '/finance-software-development/',
    icon: 'finance.webp'
  },
  {
    title: 'Education',
    content: 'At Exoft, we excel at building software that enables remote learning, adapts the learning processes to individual student needs, promotes collaboration between teachers and students, automates paperwork, or otherwise improves teaching or learning.<br><br>We can build virtual classrooms, shared document editing solutions, student forums, educational content creation tools, assessment solutions, etc.',
    url: '/education-software-development-services/',
    icon: 'education.webp'
  },
  {
    title: 'Construction',
    content: 'Tech solutions built for construction businesses are part of our company’s portfolio. We can lend you a hand with software for construction management, BIM, accounting, document management, equipment maintenance, and beyond.<br><br>Our custom development services help companies plan construction work, track construction equipment, facilitate smooth cooperation between contractors and other stakeholders, and enhance logistics on construction sites — thus reducing delays, risks, and expenses.',
    url: '/construction-software-development/',
    icon: 'construction.webp'
  },
  {
    title: 'Energy and Utilities',
    content: 'Our custom software development services benefit water and electricity distributors, facility operators, energy producers, oil refineries, and mining companies.<br><br>As a software development company with a decade of experience, we can build solutions that track production waste, notify about equipment failures, monitor asset wear and tear, and measure consumption intensity. Whether you need an electric vehicle charging app, a meter reading software, or a fuel tracking solution, our engineers can make it happen.',
    url: '/energy-and-utilities-software-development-services/',
    icon: 'energy-and-utilities.webp'
  },
  {
    title: 'Media & Entertainment',
    content: 'Media and entertainment businesses partner with us to build user-centric software solutions for smooth content distribution, accurate user segmentation, and personalized ad campaigns.<br><br>As a mature software development company, we design, architect, and develop OTT platforms, media portals, AdTech applications, and engagement analytics engines for any market size. Our M&E solutions allow you to easily create, publish, and stream digital media that win and keep audiences.',
    url: '/media-and-entertainment-software-development/',
    icon: 'media-and-entertainment.webp'
  },
];